import { onBeforeUpdate, shallowRef } from 'vue';
import lottie from 'lottie-web';
import { showDialog } from 'vant';
import 'vant/es/dialog/style';
import { showToast } from 'vant';
import 'vant/es/toast/style';
import md5 from 'blueimp-md5';
import { setupWebViewJavascriptBridge, nativeBridge } from '../native/tnative';
import tnative from '../native/tnative';
//vue3dom获取
export function useRefs() {
    const itemRefs = shallowRef([]);

    function setItemRef(el) {
        if (el && !itemRefs.value.includes(el)) {
            itemRefs.value.push(el);
        }
    }
    onBeforeUpdate(() => {
        itemRefs.value = [];
    });
    return {
        itemRefs,
        setItemRef
    };
}

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
            time = parseInt(time);
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value;
        }
        return value || 0;
    });
    return time_str;
}
export function isIOS() {
    return /iPhone|iPad|ios/gi.test(window.navigator.userAgent);
}

export function getDateDiff(dateTimeStamp) {
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let month = day * 30;
    let now = new Date().getTime();
    let diffValue = now - dateTimeStamp;
    if (diffValue < 0) {
        return;
    }
    let monthC = diffValue / month;
    let weekC = diffValue / (7 * day);
    let dayC = diffValue / day;
    let hourC = diffValue / hour;
    let minC = diffValue / minute;
    let result = '';
    if (monthC >= 1) {
        result = '' + parseInt(monthC) + '月前';
    } else if (weekC >= 1) {
        result = '' + parseInt(weekC) + '周前';
    } else if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天前';
    } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时前';
    } else if (minC >= 1) {
        result = '' + parseInt(minC) + '分钟前';
    } else result = '刚刚';
    return result;
}
//节流
export function throttle(fn, delay = 200) {
    let timer = 0;
    return function () {
        if (timer) {
            return;
        }
        timer = setTimeout(() => {
            fn.apply(this, arguments); // 透传 this和参数
            timer = 0;
        }, delay);
    };
}
//节流第一次立刻执行
export function throttleImmediate(fn, wait = 300, isImmediate = true) {
    let timeout = null;
    return (...rest) => {
        // 立即执行
        if (isImmediate) {
            !timeout && fn.call(this, ...rest);
            if (!timeout)
                timeout = setTimeout(function () {
                    clearTimeout(timeout);
                    timeout = null;
                }, wait);
        } else {
            if (!timeout)
                timeout = setTimeout(function () {
                    clearTimeout(timeout);
                    timeout = null;
                    fn.call(this, ...rest);
                }, wait);
        }
    };
}

export function debounce(callback, delay) {
    let timer = null;

    return function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, delay);
    };
}

export function umLog(category, action) {
    // eslint-disable-next-line no-undef
    _czc && _czc.push(['_trackEvent', category, action]);
}
//获取url参数
export function getUrlParam(name, url) {
    const href = url || decodeURIComponent(window.location.href);
    return (
        decodeURIComponent(
            // eslint-disable-next-line no-sparse-arrays
            (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(href) || [, ''])[1] ||
                ''.replace(/\+/g, '%20')
        ) || null
    ); // eslint-disable-line
}

// 获取URL参数
export const getUrlParams = () =>
    Object.fromEntries(new URLSearchParams(window.location.search).entries());

//跳转用户主页

export const toUserHomePage = (userID) => {
    window.location.href = `inframeskr://user/otherProfile?userId=${userID}`;
};
//window.location.href url跳转
export const locationHref = (url) => {
    const host = location.host;
    let href = 'https://act.inframe.mobi';
    if (host === 'test.act.inframe.mobi') {
        href = 'http://test.act.inframe.mobi';
    }
    console.log(href + url);
    window.location.href = href + url;
};
//震动
export const playVibrate = (time = 100) => {
    navigator.vibrate =
        navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
    if (navigator.vibrate) {
        console.log('支持设备震动！');
        navigator.vibrate(time);
    }
};

//lottie
export const initLottie = (dom, path, autoplay = true, loop = true) => {
    if (typeof dom === 'string') {
        dom = document.querySelector(dom);
    }
    // const icon = document.querySelector(dom);
    let option = {
        container: dom, // 包含动画的dom元素
        renderer: 'svg', // 渲染出来的是什么格式
        loop, // 循环播放
        autoplay // 自动播放
        //path, // 动画json的路径
        //animationData:loopBg,
    };
    if (typeof path == 'string' && path.indexOf('http') >= 0) {
        option.path = path;
    } else {
        option.animationData = path;
    }
    let a = lottie.loadAnimation(option);

    return a;
};

//头像拼接
export function getAvatarSourceUlr(data, displayType) {
    //displayType=2头像框
    if (Object.keys(data).length > 0) {
        let sourcesJson = {};
        data.forEach((item) => {
            if (item.displayType == displayType) {
                sourcesJson = JSON.parse(item.sourcesJson);
            }
        });

        if (sourcesJson.items) {
            let url = '';
            sourcesJson.items.forEach((item) => {
                if (item.type == 1) {
                    //自动循环播放，type为播放效果
                    url = item.sourceBaseURL + item.wpMURL;
                }
            });
            console.log(url);
            return url;
        } else if (sourcesJson.picURL) {
            console.log(sourcesJson.picURL);
            return sourcesJson.picURL;
        } else {
            return false;
        }
    }
}

/**
 * 图片url链接转base64
 * @param {String} url 图片链接
 * @returns 转base64后的值或者报错信息
 */
export const imgUrlToBase64 = (url) => {
    return new Promise((resolve, reject) => {
        if (!url) {
            reject('请传入url内容');
        }

        if (/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(url)) {
            // 图片地址
            const image = new Image();
            // 设置跨域问题
            image.setAttribute('crossOrigin', 'anonymous');
            // 图片地址
            image.src = url;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0, image.width, image.height);
                // 获取图片后缀
                const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
                // 转base64
                const dataUrl = canvas.toDataURL(`image/${ext}`);
                resolve(dataUrl || '');
            };
        } else {
            // 非图片地址
            reject('非(png/jpe?g/gif/svg等)图片地址');
        }
    });
};

//检查h5存储是否可用
export const isLocalStorageAvailable = () => {
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return;
    } catch (e) {
        showDialog({
            message: '请确保浏览器权限设置中，阻止cookie写入权限为关闭状态后重新进入此页面～'
        }).then(() => {
            // on close
        });
        return false;
    }
};
//打开支付链接
export const openPayUrl = (url) => {
    const form = document.createElement('form');
    document.body.appendChild(form);
    form.action = url;
    form.method = 'post';
    form.style.display = 'none';
    form.submit();
    document.body.removeChild(form);
};

function isObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]';
}

// 埋点签名
export const buryMd5Sign = (data) => {
    const temp = Object.keys(data).sort((a, b) => a.localeCompare(b)) || [];
    const str = temp
        .map((item) => {
            const value = isObject(data[item]) ? JSON.stringify(data[item]) : data[item];
            return `${item}=${value}`;
        })
        .join('&');

    return md5(str).toUpperCase();
};

// 判断当前是否在微信内
export const isWeChatBrowser = () => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
};

// 公众号内吊起微信支付
export const wxInnerPay = (
    { prepayID, timeStamp, nonceStr, sign, signType },
    successCallBack,
    cancelCallBack,
    failCallBack
) => {
    // WeixinJSBridge: 微信浏览器内置对象，其他浏览器内无效
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
            appId: 'wx573a636cec9d86ff', //公众号ID
            timeStamp, // 时间戳，自1970年以来的秒数
            nonceStr, // 随机串
            package: `prepay_id=${prepayID}`,
            signType, // 微信签名方式
            paySign: sign
        },
        (res) => {
            if (res.err_msg == 'get_brand_wcpay_request:ok') {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                successCallBack();
            } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
                cancelCallBack();
            } else {
                failCallBack();
            }
        }
    );
};
export const getDeviceToken = () => {
    let umModule;
    let deviceToken;
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        if (!ALIYUN_FP) {
            reject('ALIYUN_FP未定义');
            return;
        }
        // eslint-disable-next-line no-undef
        ALIYUN_FP.use('um', (state, um) => {
            if (state === 'loaded') {
                umModule = um;
                um.init(
                    {
                        appKey: '00aae43b1d89ba6c0c5c6fbdaac62a88',
                        appName: 'spa'
                        //endpoints : ['https://cloudauth-device.aliyuncs.com']
                    },
                    (initStats) => {
                        console.log(initStats, 'initStats');
                        if (initStats === 'success') {
                            //如果需要页面init完成就获取token，则在这里获取。
                            deviceToken = umModule.getToken();
                            resolve(deviceToken);
                        } else {
                            reject(initStats, '获取deviceToken失败');
                        }
                    }
                );
            } else {
                reject(state, '获取deviceToken失败');
            }
        });
    });
};

export const filterApiKey = (data) => {
    const keys = Object.keys(data).sort((a, b) => a.localeCompare(b));
    let str = '';
    keys.forEach((item, index) => {
        if (index !== 0) {
            str += '&';
        }
        str += `${item}=${data[item]}`;
    });
    return md5(str).toUpperCase();
};

// 判断浏览器有无写入Cookie权限
export const checkCookiePermission = () => {
    let hasCookiePermission = false;
    // 尝试写入一个测试Cookie
    document.cookie = 'testCookie=1';

    // 检查是否成功写入Cookie
    if (document.cookie.indexOf('testCookie') > -1) {
        hasCookiePermission = true;
        // 删除测试Cookie
        document.cookie = 'testCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
    return hasCookiePermission;
};
// 加密接口
export const handleRequest = (data) => {
    let requestTimer = null;
    let requestAmount = 0;
    return new Promise((resolve) => {
        try {
            const bridge = nativeBridge.bridge || window.WebViewJavascriptBridge;
            if (bridge) {
                tnative
                    .requestSign(data)
                    .then((res) => {
                        if (res) {
                            resolve(res);
                        } else {
                            requestTimer = setInterval(() => {
                                console.log('requestAmount', requestAmount);
                                if (requestAmount > 100) {
                                    clearInterval(requestTimer);
                                    requestTimer = null;
                                    resolve(false);
                                    return;
                                }
                                requestAmount++;
                                retryResign(resolve, data, requestTimer);
                            }, 50);
                        }
                    })
                    .catch(() => {
                        requestTimer = setInterval(() => {
                            console.log('requestAmount', requestAmount);
                            if (requestAmount > 100) {
                                clearInterval(requestTimer);
                                requestTimer = null;
                                resolve(false);
                                return;
                            }
                            requestAmount++;
                            retryResign(resolve, data, requestTimer);
                        }, 50);
                    });
            } else {
                setupWebViewJavascriptBridge().then(() => {
                    tnative
                        .requestSign(data)
                        .then((res) => {
                            if (res) {
                                resolve(res);
                            } else {
                                requestTimer = setInterval(() => {
                                    console.log('requestAmount', requestAmount);
                                    if (requestAmount > 100) {
                                        clearInterval(requestTimer);
                                        requestTimer = null;
                                        resolve(false);
                                        return;
                                    }
                                    requestAmount++;
                                    retryResign(resolve, data, requestTimer);
                                }, 50);
                            }
                        })
                        .catch(() => {
                            requestTimer = setInterval(() => {
                                console.log('requestAmount', requestAmount);
                                if (requestAmount > 100) {
                                    clearInterval(requestTimer);
                                    requestTimer = null;
                                    resolve(false);
                                    return;
                                }
                                requestAmount++;
                                retryResign(resolve, data, requestTimer);
                            }, 50);
                        });
                });
            }
        } catch (error) {
            console.log('执行失败bridge');
            requestTimer = setInterval(() => {
                console.log('requestAmount', requestAmount);
                if (requestAmount > 100) {
                    clearInterval(requestTimer);
                    requestTimer = null;
                    resolve(false);
                    return;
                }
                requestAmount++;
                retryResign(resolve, data, requestTimer);
            }, 50);
        }
    });
};

/**
 * @param url // 文件cdn
 */

export const getFileSize = async (url) => {
    const fileExtension = url.slice(url.lastIndexOf('.') + 1);
    if (fileExtension === 'mp4') {
        const video = document.createElement('video');
        video.setAttribute('width', '10');
        video.setAttribute('height', '10');
        video.setAttribute('style', 'position: absolute;opacity: 0;z-index: -5;top: 0;');
        video.setAttribute('src', url);
        document.body.appendChild(video);
        const p = new Promise((resolve, reject) => {
            video.addEventListener('canplay', () => {
                resolve({
                    width: video.videoWidth / 2,
                    height: video.videoHeight
                });
                reject('获取宽高错误！, mp4');
            });
        });
        const res = await p;
        document.body.removeChild(video);
        return res;
    } else {
        return new Promise((resolve, reject) => {
            const imgObj = new Image();
            imgObj.src = url;
            imgObj.onload = () => {
                resolve({
                    width: imgObj.width,
                    height: imgObj.height
                });
                reject('获取宽高错误！ 其他');
            };
        });
    }
};
const retryResign = (resolve, data, requestTimer) => {
    try {
        const bridge = nativeBridge.bridge || window.WebViewJavascriptBridge;
        if (bridge) {
            // console.log('执行加载完事');
            tnative
                .requestSign(data)
                .then((res) => {
                    if (res) {
                        clearInterval(requestTimer);
                        requestTimer = null;
                        resolve(res);
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                });
        } else {
            setupWebViewJavascriptBridge().then(() => {
                tnative
                    .requestSign(data)
                    .then((res) => {
                        if (res) {
                            clearInterval(requestTimer);
                            requestTimer = null;
                            resolve(res);
                        }
                    })
                    .catch((err) => {
                        console.log('err', err);
                    });
            });
        }
    } catch (error) {
        console.log('err', error);
    }
};

// 二进制转base64
export const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

// 二进制转json
export const parseBinaryToJson = (binaryData) => {
    // 创建一个新的Uint8Array视图来操作二进制数据
    const uint8Array = new Uint8Array(binaryData);
    // 使用TextDecoder解码二进制数据
    const decoder = new TextDecoder('utf-8');
    const jsonString = decoder.decode(uint8Array);
    // 尝试解析字符串为JSON
    try {
        const jsonData = JSON.parse(jsonString);
        return jsonData;
    } catch (e) {
        console.error('解析错误:', e);
        return null;
    }
};

/**
 * 取倒数第一位小数
 * @param {数字} num
 * @returns
 */
export const toDecimal = (num, len = 1) => {
    if (!num || typeof num !== 'number' || Number.isNaN(num)) return '0.0';
    const nums = (num + '').split('.');
    let res;
    if (nums.length > 1) {
        res = nums[0] + '.' + nums[1].slice(0, len);
        if (`${nums[1]}`.length < len) {
            res += '0'.repeat(len - `${nums[1]}`.length);
        }
    } else {
        res = num + '.' + '0'.repeat(len);
    }
    return res;
};

// 计算字符串长度
export const fittingString = (str) => {
    if (!str) return '';
    str = (str || '').toString();
    let strLen = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
            strLen++;
        } else {
            strLen += 2;
        }
    }
    return strLen;
};
// 复制
export const handleCopy = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text).then(() => {
            showToast('复制成功');
        });
    } else {
        let copyInput = document.createElement('input');
        document.body.appendChild(copyInput);
        copyInput.setAttribute('value', text);
        copyInput.select();
        document.execCommand('Copy');
        showToast('复制成功');
        copyInput.remove();
    }
};

// 混淆query参数
export const obfuscateQueryParam = (param) => {
    return btoa(encodeURIComponent(param));
};
// 解混淆query参数
export const deobfuscateQueryParam = (obfuscatedParam) => {
    return decodeURIComponent(atob(obfuscatedParam));
};
