import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    //我的客服

    //登陆
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue')
    },
    //登陆
    {
        path: '/logint',
        name: 'logint',
        component: () => import(/* webpackChunkName: "login" */ '../views/logint/index.vue'),
        meta: {
            hidBar: true
        }
    },
    // 特价超市- 这个直接跳转充值助手
    {
        path: '/nchs',
        name: 'nchs',
        component: () => import(/* webpackChunkName: "nchs" */ '../views/nchs/index.vue'),
        meta: {
            hidBar: false
        }
    },
    // 新特价超市
    {
        path: '/nnchs',
        name: 'nnchs',
        component: () => import(/* webpackChunkName: "nchs" */ '../views/nnchs/index.vue'),
        meta: {
            hidBar: false
        }
    },
    //充值助手
    {
        path: '/alipay',
        name: 'alipay',
        component: () => import(/* webpackChunkName: "alipay" */ '../views/alipay/index.vue')
    },
    //充值助手
    {
        path: '/nAlipay',
        name: 'nAlipay',
        component: () => import(/* webpackChunkName: "nAlipay" */ '../views/nAlipay/index.vue')
    },
    // 签到提现
    {
        path: '/wxcash',
        name: 'wxcash',
        component: () => import(/* webpackChunkName: "wxcash" */ '../views/wxcash/index.vue')
    },
    {
        path: '/wxcash/cashrecords',
        name: 'cashrecords',
        component: () =>
            import(/* webpackChunkName: "cashrecords" */ '../views/wxcash/cashrecords.vue')
    },
    {
        path: '/wxcash/cash',
        name: 'cash',
        component: () => import(/* webpackChunkName: "cash" */ '../views/wxcash/cash.vue')
    },
    {
        path: '/wxcash/cashdetail',
        name: 'cashdetail',
        component: () =>
            import(/* webpackChunkName: "cashdetail" */ '../views/wxcash/cashdetail.vue')
    },
    {
        path: '/wxcash/cashoption',
        name: 'cashoption',
        component: () =>
            import(/* webpackChunkName: "cashoption" */ '../views/wxcash/cashoption.vue')
    },
    {
        path: '/benefit/hz',
        name: 'hz',
        component: () => import(/* webpackChunkName: "hz" */ '../views/benefit/hz.vue')
    },
    // 企业微信单个商品推送活动
    {
        path: '/wechat-shop',
        name: 'wechat-shop',
        component: () =>
            import(/* webpackChunkName: "wechat-shop" */ '../views/wechat-shop/index.vue')
    },
    // 拉新提现
    {
        path: '/wxcash-invite',
        name: 'wxcash-invite',
        component: () =>
            import(/* webpackChunkName: "wxcash-invite" */ '../views/wxcash-invite/index.vue')
    },
    {
        path: '/wxcash-invite/cash',
        name: 'cash-invite',
        component: () =>
            import(/* webpackChunkName: "cash-invite" */ '../views/wxcash-invite/cash.vue')
    },
    {
        path: '/wxcash-invite/cashoption',
        name: 'cashoption-invite',
        component: () =>
            import(
                /* webpackChunkName: "cashoption-invite" */ '../views/wxcash-invite/cashoption.vue'
            )
    },
    // k歌拿现金
    {
        path: '/K-sing',
        name: 'K-sing',
        component: () => import(/* webpackChunkName: "K-sing" */ '../views/K-sing/index.vue')
    }
];
const router = createRouter({
    history: createWebHistory('/'),
    routes
});
export default router;
